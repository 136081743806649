export const styles = {
    projectDevelopmentContainer: {
        mb: '10rem'
    },
    projectDevelopmentTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '2rem'
    },
    projectDevelopmentDetailBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '1rem',
    },
    projectDevelopmentDetailTitle: {
        width: '100%',
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    projectDevelopmentDetailText: {
        width: '100%',
        fontFamily: 'inherit',
        fontWeight: 400,
        mb: '2rem'
    },
};