import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function Needs(props){
    return (
        <Container maxWidth="unset" sx={styles.needsContainer} className="needsContainer">
            <VisibilityMotion component=
                {
                    <Box>
                        <Typography
                            variant='h4'
                            sx={styles.needsTitle}
                        >
                            Unerstand Needs
                        </Typography>
                        {props.needs.map((needsParagraph, index)=>(
                            <Typography
                                variant='h5'
                                sx={styles.needsDetail}
                                key={index}
                            >
                                {needsParagraph}
                            </Typography>
                        ))}
                    </Box>
                }
            />
        </Container>
    );
}