import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function DevelopmentProcess(props) {
    return (
        <Container maxWidth="unset" sx={styles.projectDevelopmentContainer}>
            <VisibilityMotion component=
                {
                    <Box sx={styles.projectDevelopmentBox} className="projectDevelopmentBox" >
                        <Typography
                            variant='h4'
                            sx={styles.projectDevelopmentTitle}
                        >
                            Development Process
                        </Typography>
                        <Box sx={styles.projectDevelopmentContentBox} >
                            {props.development.map((detail, index)=>(
                                <Box sx={styles.projectDevelopmentDetailBox} key={index} >
                                    <Typography
                                        variant='h5'
                                        sx={styles.projectDevelopmentDetailTitle}
                                    >
                                        {detail.title}
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={styles.projectDevelopmentDetailText}
                                    >
                                        {detail.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                }
            />
        </Container>
    );
}